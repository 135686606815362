import React from "react"

// Layout
import Layout from "layouts/layout-primary"

// Sections
import SEO from "components/seo"
import Hero from "sections/retention-prediction/hero"
import ReduceTurnover from "sections/retention-prediction/reduce-turnover"
import PuttingData from "sections/retention-prediction/putting-data"

const RetentionPrediction = () => (
  <Layout showNewsletter={false}>
    <SEO title="Retention Prediction | Arena Analytics" description="" />
    <Hero />
    <ReduceTurnover />
    <PuttingData />
  </Layout>
)

export default RetentionPrediction
