import React from "react"

// Utils
import breakpoints from "utils/breakpoints"
import { colors } from "utils/variables"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
// Components
import Container from "components/container"

const StyledPuttingData = styled.section`
  padding: 64px 0;
  background: ${colors.silver__extra_pale};
  position: relative;

  ${breakpoints.medium`
    padding: 122px 0 141px 0;
  `}

  ${breakpoints.large`
    padding: 182px 0 241px 0;
  `}

  .icon {
    left: 0;
    ${breakpoints.small`
      position: absolute;
      left: -12px;
      top: 60px;
    `}
    ${breakpoints.medium`
      left: 0;
      top: 100px;
    `}
    ${breakpoints.large`
      top: 54px;
    `}
  }

  .integrations-link {
    text-decoration: underline;
  }
`

const PuttingData = () => (
  <StyledPuttingData>
    <StaticImage
      src="../../assets/images/retention-prediction/retention-prediction-img.png"
      alt=""
      className="icon col-10 col-sm-5 col-md-4 mb-5"
    />
    <Container>
      <div className="row justify-content-end ">
        <div className="col-12 col-sm-7 col-md-8">
          <h2 className="h2 maxi d-none d-lg-block">
            Making <span className="color--turquoise__primary">Data </span>
            Work for You
          </h2>
          <h2 className="h2 maxi title d-lg-none">
            Making <span className="color--turquoise__primary">Data</span>
            <br /> Work for You
          </h2>
          <p className="text--large mt-3 mt-md-4 mb-4">
            Our innovative model predicts one very specific outcome: is the
            candidate likely to stay in the position for a set period of time?
            We then apply data science and machine learning tools to identify
            patterns and predict which candidates are most likely to be retained
            by the organization if hired.
          </p>
          <br />
          <p className="text--large ">
            Our Retention Prediction Solution{" "}
            <span className="">
              <Link
                to="/integrations"
                target="blank"
                className="d-inline integrations-link"
              >
                easily integrates with any Applicant Tracking System
              </Link>
            </span>{" "}
            for a seamless experience.
          </p>
        </div>
      </div>
    </Container>
  </StyledPuttingData>
)

export default PuttingData
