import React from "react"

// Libraries
import styled from "styled-components"
import { motion } from "framer-motion"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import HeroArrow from "components/hero-arrow"
import { colors } from "utils/variables"

const StyledHero = styled.section`
  min-height: calc(100vh - 62px - 58px);
  position: relative;
  display: flex;
  align-items: center;
  padding: 32px 0;
  overflow: visible !important;

  ${breakpoint.medium`
    min-height: calc(100vh - 96px - 58px);
  `}

  .hero__arrow {
    button {
      background-color: ${colors.navy__extra_dark} !important;

      svg {
        * {
          stroke: ${colors.white};
        }
      }

      &:hover {
        background-color: ${colors.turquoise__primary} !important;
      }
    }

    .arrow__illustration {
      * {
        fill: ${colors.white};
      }
    }
  }

  .hero__text {
    max-width: 448px;
    width: 100%;
  }
`

const Hero = () => (
  <StyledHero>
    <Container>
      <div className="row align-items-center justify-content-between">
        <div className="col-12 col-sm-6 col-md-5 mb-4 mb-sm-0">
          <motion.div
            initial={{ y: 32, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.5,
            }}
            className="hero__content"
          >
            <h1 className="h1 maxi mb-2 mb-md-4 text-center text-sm-start">
              Boost <br />{" "}
              <span className="color--turquoise__primary">Retention</span>
            </h1>

            <p className="text--large text-center text-sm-start mb-4">
              Helping employers retain staff and drastically reduce turnover
              costs
            </p>
          </motion.div>
        </div>

        <motion.div
          initial={{ x: 100 }}
          whileInView={{ x: 0 }}
          viewport={{ once: true }}
          className="col-12 col-sm-6 col-md-7"
        >
          <StaticImage
            className="hero__image mb-2"
            src="../../assets/images/retention-prediction/retention-prediction-hero.png"
            alt=""
          />
        </motion.div>
      </div>
    </Container>

    <HeroArrow className="hero__arrow" />
  </StyledHero>
)

export default Hero
