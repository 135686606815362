import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables"

// Componentes
import Container from "components/container/"

const StyledReduceTurnover = styled.section`
  padding: 64px 0;
  background: ${colors.navy__extra_dark};
  color: ${colors.white};

  ${breakpoint.medium`
    padding: 142px 0 112px 0;
  `}
`

const ReduceTurnover = () => (
  <StyledReduceTurnover>
    <Container>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <h2 className="h2 maxi text-center">
            <span className="color--turquoise__primary">Cut Turnover </span>
            with the Power of Predictive Analytics
          </h2>
          <p className="text--large mt-3 mt-sm-4">
            The cutting-edge Arena Retention Prediction model is a powerful
            predictive analytics tool. Analyzing data about each candidate from
            a variety of source datasets specific to each role, organization,
            and location, it predicts the likelihood that a given candidate will
            be retained in a particular position if hired.
          </p>
        </div>
      </div>
    </Container>
  </StyledReduceTurnover>
)

export default ReduceTurnover
